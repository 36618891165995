import moment from "moment";

export const isLive = true;
export const isStaging = !true;
const localApiUrl = "http://192.168.1.96:8000"; //Ruchit
// "http://192.168.0.159:5000"; // Viraj

const liveApiUrl = "https://api.scrapc.com";
const StagingLiveApiUrl = "https://uatapi.scrapc.com";

const siteUrl = isLive
  ? isStaging
    ? "https://uatweb.scrapc.com"
    : "https://app.scrapc.com"
  : "http://localhost:3002";

const adminUrl = isLive
  ? isStaging
    ? "https://uatboardman.scrapc.com"
    : "https://boardman.scrapc.com"
  : "http://localhost:3001";

const apiUrl = isLive
  ? isStaging
    ? StagingLiveApiUrl
    : liveApiUrl
  : localApiUrl;

const livePriceUrl = "https://scrapc.com/news/today-scrap-rate/";

const BaseSetting = {
  // reCaptcha key [ do not remove] ]
  captchaKey: "6LfxJQopAAAAAO1Djq3uxEzUjSWcTmFBQF48Grbm",
  siteUrl: siteUrl,
  adminUrl: adminUrl,
  siteName: "",
  siteIcon: "",
  copyRightText: `Copyright © ${moment().year()} by ScrapC. All Rights Reserved`,
  socketURL: "",
  GOOGLE_CLIENT_ID:
    "562218573711-t36pldvr3d06cvuup0gs5e7sdigfd1n4.apps.googleusercontent.com",
  GOOGLE_PIN_CODE_API_KEY: "AIzaSyCm46euRGUbbk-ZGzTVj2c9l12go9vc4KQ",
  GOOGLE_TRANSLATE_API_KEY: "AIzaSyCDrjvDqO-7Zic2Ykhci3s_LILrj7eObqA",
  apiUrl: apiUrl,
  revalidate: true,
  templateKey: "",
  mapKey: "",
  livePriceUrl: livePriceUrl,
  endpoint: {
    signUp: "/user/signup",
    generateOtp: "/user/generate-otp",
    verifyOtp: "/user/verify-otp",
    login: "/user/log-in",
    // logOut: "/user/logout",
    logOut: "/user/new-logout",
    resetPassword: "/user/reset-password",
    uploadProfileImg: "/user/upload-profile-image",

    singlePostImageUpload: "/post/upload-post-image",
    changeMainImage: "/post/change-main-image",
    updatePost: "/post/update-post",

    addUserInfo: "/user/add-user-info",
    userData: "/user/get-user-data",
    socialLogin: "/user/social-connect",
    getUserModelData: "/user/get-user-model-data",

    getProductList: "/post/get-post-list",
    getpostdetails: "/post/get-post-details",
    getuserrelatedposts: "/post/get-user-related-posts",
    getcategoryrelatedposts: "/post/get-category-related-posts",

    // Admin API's
    categoryList: "/admin/find-parent-category",
    subCategoryList: "/admin/find-subcategory",
    conditionList: "/admin/find-condition",
    subCategoryByParentIdList: "/admin/find-subcategory-by-parent-id",
    businessTypeList: "/admin/all-business-type",
    msgSuggestionList: "/admin/all-icebreaking-question",
    countriesList: "/admin/get-all-country-list",
    notificationPreferences: "/admin/get-notification-preferences",
    changeNotificationPreferences: "/admin/update-notification-preference",

    // Dropdown Apis
    commonData: "/user/get-common-data",
    countryList: "/user/get-country-list",
    languageList: "/user/get-language-list",
    currencyList: "/user/get-active-currency-list",
    staticImages: "/user/get-static-images",

    getcartlist: "/post/get-cart-list",
    addposttocart: "/post/add-post-to-cart",
    removeCart: "/post/remove-cart",
    intrestedClick: "/post/intrested-click",

    // my offers
    getMyOffer: "/post/get-myoffers-list",

    addtowishlist: "/post/add-to-wishlist",
    favoriteList: "/post/get-wish-list",
    addwishlisttocart: "/post/add-wishlist-to-cart",
    removewishlist: "/post/remove-wishlist",

    createBuyPost: "/post/add-buy-post",
    createSellPost: "/post/add-sell-post",
    createUpdateSellPost: "/post/add-or-update-sellpost",
    createUpdateBuyPost: "/post/add-or-update-buypost",
    removeFile: "/user/remove-file",
    removeDocumentFile: "/user/remove-document-file",

    sendInquiry: "/post/send-inquiry",

    followUser: "/user/follow-user",
    getProfileViews: "/user/get-profile-views",
    getFollowings: "/user/get-followings",
    getFollowers: "/user/get-followers",
    unFollowUser: "/user/unfollow-user",
    removeFollower: "/user/remove-follower",
    reportUser: "/user/report-user",
    rateUser: "/user/rate-the-user",
    getUserRating: "/user/get-user-ratings",

    //profile update
    profileUpdate: "/user/update-user-info",

    // change password
    changePassword: "/user/user-change-password",

    // change register email or phone
    verifyEmailOrPhone: "/user/verify-registered-emailOrPhone",
    changeEmailOrPhone: "/user/change-registered-emailOrPhone",
    verifyOtpAndUpdateEmail:
      "/user/verify-otp-and-update-registered-emailOrPhone",

    // search apis
    searchList: "/post/search-list",
    removeSearchList: "/post/remove-search-history",
    searchData: "/post/search-see-all",

    // Alert
    alertList: "/post/alert-list",
    alertDelete: "/post/delete-alert",
    alertStatusChange: "/post/change-status",
    alertOne: "/post/get-one-alert",
    alertCreate: "/post/create-alert",
    updateAlert: "/post/update-alert",

    getOneLogistic: "/user/get-logistic",
    createLogistics: "/user/create-logistic",
    getPostList: "/post/post-list",
    createLogisticsUser: "/user/save-logistic",

    // contact us
    contactUsApi: "/user/contact-us",
    subscribeApi: "/user/subscribe",
    getOnePostData: "/admin/get-single-post-data",
    deletePost: "/admin/delete-post",
    getCMS: "/admin/get-cms",

    // social media links
    socialLinks: "/user/get-social-links",

    tempCheckIsCompress: "/post/is-compress",
    //soldstatus
    postSold: "/post/post-sold",

    allLanguageList: "/user/get-translation-active-languages",
    getLanguage: "/user/get-language-data",

    directLogin: "/user/generate-otp-by-web",
    hidePreferences: "/user/hide-my-details",

    verifyOtpForOtherEmailOrPhone: "/user/verify-emailORphone",
    generateOtpForOtherEmailOrPhone: "/user/generate-emailORphone",

    signupUsingOtp: "/user/signup-with-otp",
    countryStateCityListByPincode: "/admin/get-country-state-city",
    stateList: "/user/get-state-list",
    cityList: "/user/get-city-list",
    getSEOList: "/admin/get-seo-details-list",
  },
};

export default BaseSetting;
